html,
body {
	min-height: 100%;
	overflow: hidden;
}
body {
	height: 100vh;
	font-size: 0.875rem !IMPORTANT;
	/* must override the default line-height from MUI */
	line-height: 1.43 !IMPORTANT;
}
#root {
	height: 100%;
}
